@import '../../utils/theme.scss';

.customLink{
    cursor: pointer;
    color: $brand-color;
    padding: 0px;
    border: none;
    background: none;
}

.minContainer{
    min-height: 40vh;
}

.customButton{
    color: #fff;
    padding: 0.5rem 2rem;
    border: none;
    background-color: $brand-color;
}

.customButton:disabled{
    opacity: 0.6;
}

.validators{
    display: block;
    background-color: #FFBABA;
    color: #D8000C;
    font-size: 12px;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid;
}

.formErrors{
    font-size: 12px;
    color: red;
    display: block;
    height: 20px;
}

.customInput {
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
}
.forgotPasswordCard{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}