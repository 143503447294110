.tableBlock {
    thead {
        tr {
            th {
                font-size: 14px;
                color: #6E6E6E;
                background: #F8FAFC;
                border-bottom: 0;

                &:first-child {
                    padding-left: 1.5rem;
                }
    
                &:last-child {
                    padding-right: 1.5rem;
                }
            }
           
        }
    }

    tbody {
        tr{
            td {
                .idxStyle {
                    padding: 5px 10px;
                    background-color: #F6F6F6;
                }

                a {
                    text-decoration: none;
                }
            }

            td:first-child {
                padding-left: 1.5rem;
            }

            td:last-child {
                padding-right: 1.5rem;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.linkText {
    color: #1CA8C3;
    &:hover {
        // text-decoration: underline;
        color: #1CA8C3 !important;
    }
}

.expandableBlock {
    svg {
        color: #1CA8C3;
    }
}

// .expandableTable {
//     height: 40vh;
//     overflow-y: auto;
// }


