@media (max-width: 992px) {

    .chart-container{
        width:100%;
        overflow: auto;
    }
    
    #double_layer_stack_bar{
        width: 1230px;
        overflow: auto;
    }
}