@media (max-width: 768px) {

    .chart-container_sunburst{
        width:100%;
        overflow: auto;
    }
    
    #sunburst{
        width: 928px;
        overflow: auto;
    }
}