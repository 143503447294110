@media (max-width: 768px) {

    .chart-container{
        width:100%;
        overflow: auto;
    }
    
    #multi_line{
        width: 1000px;
        overflow: auto;
    }
}
