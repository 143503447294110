.searchStyle {

    &:focus {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    }
    .customInputStyle {
        border: 1px solid #ced4da;
        &:focus {
            border: 1px solid #ced4da;
            outline: 0;
            box-shadow: none;
        }

        .input-group-text {
        font-size: 12px;
        color: red;
    }
        
    }
}