/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap'); */
:root {
  --mcColorDark: #7C2F8D;
  --mcColorLight: #9c50ad;
}

.btn-info {
  background-color: var(--mcColorDark) !important;
  border: var(--mcColorDark) !important;
}

.btn-info:hover {
  background-color: var(--mcColorLight) !important;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.mc-px-5 {
  padding-right: 1rem!important;
  padding-left: 4.2rem!important;
}

.mc-shadow-end {
  box-shadow: 8px 0 15px -4px rgba(0,0,0,.075);
}

.selectedBorderList {
  border: 1px solid var(--mcColorLight) !important;
  background: aliceblue;
}

.linkText { 
  color: #1CA8C3;
}

input.form-check-input:checked {
  background-color: var(--mcColorDark);
  border-color: var(--mcColorDark);
}


.table-hover>tbody>tr.expandableTable:hover>* {
  --bs-table-accent-bg: #ffffff !important; /* Assuming you want the hover color to be white */
}

.form-check-label {
  cursor: pointer;
}

.text-reset {
  text-decoration: none;
  font-weight: 500;
}

.noUnderline {
  text-decoration: none;
}

.example-custom-input {
  width: 120px !important;
}

/* .focus {
  border: 1px solid #1CA8C3 !important;
} */

.modal-backdrop {
  display: none;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.file {
  color: #7C2F8D
}