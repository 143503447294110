.button-group-donut_graph{
    justify-content: flex-end !important;
}

@media screen and (min-width: 768px) and (max-width: 900px){
    .accessTypeStyle .w-75 {
        width: 85% !important;
    }
}

@media (max-width: 768px) {

    .chart-container_donut_graph{
        width:100%;
        overflow: auto;
    }
    
    #donut_graph{
        width: 680px;
        overflow: auto;
    }
}

@media (max-width: 400px) { 
    .button-group-donut_graph{
        // align-items: center;
        justify-content: flex-start !important;
    }
}