@import "../../../utils/theme.scss";
$mc-color-dark: #7C2F8D;
$mc-color-light: #9c50ad;

.sidebarStyleMin {
    position: absolute;
    height: 100vh;
    left: 0;
    background: white;
    top: 0;
}

.mainHeadingMin {
    font-size: 16px;
    padding: 26px 16px 25px 16px;
}

.listItemMin {
    color: #4D4D4D;
    font-weight: 400;
    padding: 16px;
    font-size: 16px;

    &:hover {
        background-color: rgba(226, 225, 226, 0.72);
        color:  $mc-color-dark;
        cursor: pointer;
    }
}

.activeMin {
    background-color: rgb(212 189 217 / 72%);
    color: $mc-color-dark;
    font-weight: 600;
    padding: 16px;
}

.sidebarStyle {
    width: 23.75rem !important;

    .menuTitle {
        td {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0 5px 10px 13px;
        }
    }
}

.listItem {
    font-size: 16px;
    color: #4D4D4D;
    font-weight: 400;
    padding: 0.5rem;

    &:hover {
        background-color: rgba(28, 168, 195, 0.07);;
        color: $mc-color-dark;
        cursor: pointer;
    }

    td {
        padding: 10px 5px;
        font-size: 16px;
    }
}

.active {
    // background-color: rgba(28, 168, 195, 0.07);
    color: #1CA8C3;
    font-weight: 600;

    td {
        padding: 10px 2px;
    }
}

@media screen and (min-width: 350px ) and (max-width: 600px) {
    .sidebarStyle {
        width: 18.75rem !important;
    }
}
