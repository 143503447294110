@import '../../utils/theme.scss';

//Layout CSS

.root {
    position: static;
    bottom: 0;
}

//Header CSS
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.3rem;
}

.dropdownColor{
    color: white;
    background-color: #3D415A;
}

.dropdownColor:hover{
    color: white;
    background-color: #3D415A;
}

//Footer CSS

.footer1 {
    background-color:  #DD962A;
    color: #000000;
    min-height: 414px;
    align-content: flex-end;
    justify-content: space-around;
    padding: 2px 0px 15px 35px;
}

.footer2 {
    background-color:  #7C2F8D;
    color: white;
    min-height: 57px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    padding-left: 4.2rem!important;
}



.footer1 p {
    font-style: normal;
    margin-bottom: 5px;
    color: #000000;
    text-align: start;
}

.footer2 a{
    text-decoration: none;
}


@media only screen and (min-width: 600px){
    .modalWidth{
        width: 420px !important;
    }
}

.formErrors{
    font-size: 12px;
    color: red;
    display: block;
    height: 20px;
    margin-bottom: 0.5rem;
}

.closeBtn{
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
}

.validators{
    display: block;
    background-color: #FFBABA;
    color: #D8000C;
    font-size: 12px;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid;
}

