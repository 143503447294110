@import "../../utils/theme.scss";
.nav.customTabStyle {
    border-bottom: 1px solid gray;
    .nav-tabs {
        border-bottom: 0;
        
    }
    .nav-link.active {
        border-bottom: 2px solid $brand-color;
        color: $brand-color;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }

    .nav-link {
        color: $secondary-color;
        font-weight: 500;
        border-left: 0;
        border-right: 0;
        border-top: 0;

        &:hover {
            border-left: 0;
            border-right: 0;
            border-top: 0;
        }
    }

     
}



