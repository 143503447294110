@media (max-width: 950px) {
  .chart-container {
    width: 100%;
    overflow: auto;
  }

  #multi_bar_custom_chart {
    width: 1230px;
    overflow: auto;
  }
}
