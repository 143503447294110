  
  .dropdown-container {
    position: relative;
    cursor: pointer;
  }
  
  .dropdownStyle {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    display: none;

    z-index: 9999;
  }

  .dropdownOverflow {
    max-height: 18rem;
    overflow: auto;
  } 
  
  .dropdownStyle.visible {
    display: block;
  }

  .selectedValueContainer {
    height: 35px;
    .box{
        padding: 0.75rem;
        white-space: nowrap;
        max-width: 90%;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }   
  }



  input.customInput {
    &:focus {
        border:0;
        box-shadow: none;
    }
  }

  .flex-box {
    display: flex;
    align-items: center;
  }

  
  .flex-box-auto {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  