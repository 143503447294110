// .chart-container{
//     height: 300px;
//     width: 100%;
//  	border: solid black 1px;
//   }


@media (max-width: 1060px) { //768px

    .chart-container{
        width:100%;
        overflow: auto;
    }
    
    #multi_line{
        width: 1000px;
        overflow: auto;
    }
}
