@import 'react-datepicker/dist/react-datepicker-cssmodules.css';
@import "../../utils/theme.scss";

.example-custom-input {
    background:none;
    padding: 0.5rem;
    width: 143px;
    outline: none;
    border: 0;
  }
  
  .example-custom-input:focus-visible {
    outline: none;
  }

  span[role="alert"] {
    display: none;
  }

  button.btn-info.btnDefault {
    color: #fff;
    background-color: #1CA8C3;
    border-color: #1CA8C3;
     &:hover {
      color: #fff;
      background-color: #1CA8C3;
      border-color: #1CA8C3;
     }
  }

  button.btnDanger {
    border: 2px solid #DB504A;
    color: #DB504A;
    background: transparent;
    padding: 5px 0;
    border-radius: 5px;
    
    &:hover {
      border: 2px solid #DB504A;
    }
  }

  .fs-12 {
    font-size: 0.75rem;
  }

  .fs-14 {
    font-size: 0.875rem;
  }

  .fs-16 {
    font-size: 1rem;
  }

  .fs-20 {
    font-size: 1.25rem;
  }

  .fs-24 {
    font-size: 1.5rem;
  }

    @media screen and (max-width: 648px) {
      .datePickerBlock {
        display: flex;
        align-items: center !important;
      }

      div.sm-flex-left {
        flex-direction: column;
        align-items: flex-start !important;
      }


  }

  @media screen and (max-width: 500px) {
    .mc-btn-group {
      flex-direction: column;
    }
  }
 