.titleStyle {
    font-size: 32px;
    font-weight: 700;
}

.loaderBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    font-weight: 600;
}
