.paginationBlock {
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.exportBlock {
    width: 30%;

    .dropdown-menu {
        width: max-content;
    }
}

button.exportBtnStyle{
    background-color: white;
    border-radius: 5px;
    padding: 0.3rem 1rem;

    svg.file {
        color: #7C2F8D;
    }
}

.listItem {
    padding: 0.4rem 1rem;
    cursor: pointer;

    &:hover {
        background-color: #1CA8C314;
        svg.file {
                color: #1CA8C3;
        }
    }
}

.gotoTopStyle {
    top: -1.5rem;
    position: relative;
    right: 10px;
    padding: 0px 10px;
    background-color: #1CA8C3;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    float: right;
}

.notFound {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}



    @media screen and (max-width: 581px) {
        div.sm-flex-column-contaniner {
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start !important;
        display: flex;
    }

    .exportBlock {
        width: fit-content;   //For small screen     
    }
}
