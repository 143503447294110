// #tooltipGroup{
//     position: relative;
//     z-index: 3000 !important; 
// }

.groups rect {
    stroke: white;
    stroke-width: 0.1px;
}

@media (max-width: 1280px) {

    .chart-container_tree_map{
        width:100%;
        overflow: auto;
    }
    
    #tree_map{
        width: 1230px;
        overflow: auto;
    }
}