.grid-lines line {
  stroke: gray;
  stroke-opacity: 0.4;
}

.flex-container{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}


.smallScreenWarning {
  display: none;
}

.mainContainer {
  display: block;
}

@media only screen and (max-width: 500px){
  .smallScreenWarning{
    display: block;
  }

  /* .mainContainer {
    display: none;
  } */

}