.paginationBlock {
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.exportBlock {
    width: 35%;
}

button.exportBtnStyle{
    background-color: white;
    border-radius: 5px;
    padding: 0.3rem 1rem;
        svg.file {
            color: #7C2F8D;
        }
}

.listItem {
    padding: 0.4rem 1rem;
    cursor: pointer;

    &:hover {
        background-color: #ECECEC;
    }
}
