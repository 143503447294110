@media (max-width: 992px) {

    .chart-container_grouped_horizontal_bar{
        width:100%;
        overflow: auto;
    }
    
    #grouped_horizontal_bar{
        width: 1230px;
        overflow: auto;
    }
}