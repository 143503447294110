// .tooltip {
//     position: absolute;
//     text-align: center;
//     width: auto;
//     height: auto;
//     padding: 8px;
//     font: 12px sans-serif;
//     background: lightsteelblue;
//     border: 0px;
//     border-radius: 8px;
//     pointer-events: none;
//     opacity: 0;
//     transition: opacity 0.3s;
// }

@media (max-width: 768px) {

    .chart-container{
        width:100%;
        overflow: auto;
    }
    
    #stack_bar{
        width: 800px;
        overflow: auto;
    }
}